@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');


#app {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: #1C282F;
  /* background-color: #EBF0F4; */  
  background-color: #e1e8ed;
  font-family: 'Roboto', sans-serif;
}

a, .link {
  display: inline-block;
  color: #008cdb;
  text-decoration: none;
  cursor: pointer;
}
a:hover, .link:hover {
  color: #29363E;
  text-decoration: underline;
}
.bold {
    font-weight: 500;
}

.row {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}
.row .col {
  flex: 1;
}
.row .col.auto {
    flex: none;
}

.window-row {
  display: flex;
  gap: 30px;
}
.window-row .col {
  flex: 1;
}

@media (max-width:1800px){

  .window-row {
    margin-bottom: 30px;
    flex-direction: column;
  }
  .window-row > .col > .window:last-child {
    margin-bottom: 0 !important;
  }

}

label {
  color: #29363E;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  width: auto;
  /*text-transform: capitalize;*/
}
hr {
  height: 1px;
  background-color: #ebf0f4;
  border: none;
  margin: 20px 0;
}


.nohits {
  font-style: italic;
  color: #7B919D;
  padding: 20px;
}
.notypes {
  font-style: italic;
  color: #b7c4cc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


#login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #2a333a;
}
#login-container {  
  position: relative;
  width: 100%;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#login-container .logo {  
  margin-bottom: 20px;
}
#login-container input, #login-container button {
  display: block;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  border: 0;
  height: 40px;
  outline: none;
  color: #fff;
  border-radius: 2px;
}
#login-container input {
  background-color: #36444e;
  margin: 0 0 5px 0;
}
#login-container input::placeholder {
  color: #7B919D;
}
#login-container button {
  background-color: #008cdb;
  cursor: pointer;
  margin-top: 10px;
  text-transform: uppercase;
}

.light-link {
  align-self: flex-start;
  color: #8198a9;
  font-size: 14px;
  text-align: left;
  margin-top: 20px;
  text-decoration: none;
}
.light-link:hover {
  color: #fff;
}



button {
    height: 42px;
    border-radius: 3px;
    padding: 0 15px;
    border: 0;
    background-color: #84a0b7;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    font-family: 'Roboto';
    letter-spacing: .5px;
    text-transform: uppercase;
}
button svg {
  margin-right: 5px;
}
button:hover {
  background-color: #008cdb;
  color: #fff;
}
button:active {
    background-color: #567187;
}
button[disabled], button[disabled]:hover {
    background-color: #F6F8FA !important;
    color: #d0dde5 !important;
    border-color: #d6e1e9 !important;
    cursor: default;
}


.cancelButton {
  background-color: #D6E1E9;
  color: #506978;
}
.cancelButton:hover {
  background-color: #D6E1E9;
  color: #506978;
  opacity: .8;
}
.cancelButton:active {
    background-color: #9fb3c1;
    opacity: 1;
}
.saveButton {
  background-color: #2FC08C;
  color: #fff;
}
.saveButton:hover {
  background-color: #2FC08C;
  opacity: .8;
}
.saveButton:active {
  background-color: #189568;
  opacity: 1;
}
.deleteButton {
  background-color: #E15046;
  color: #fff;
}
.deleteButton:hover {
    background-color: #E15046;
    opacity: .8;
}
.deleteButton:active {
  background-color: #ac342c;
  opacity: 1;
}
.deleteLink {
  background-color: transparent !important;
  color: #E15046 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.deleteLink:hover {
  text-decoration: underline;
}
.deleteLink:active {
    color: #741e18 !important;
}
.blueButton {
  background-color: #008cdb;
  color: #fff;
}
.blueButton:hover {
  background-color: #008cdb;
  opacity: .8;
}

.outlineButton {
  height: 30px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  color: #29363e;
  background-color: transparent;
  border: 1px solid #d6e1e9;
}
.outlineButton:hover {
  border-color: #008cdb !important;
  background-color: transparent !important;
  color: #008cdb !important;
}


select, input, textarea {
  outline: none;
  background-color: #f4f7f9;
  border: 1px solid #d6dfe5;
  outline: none;
  border-radius: 3px;
  color: #29363E;
  font-size: 15px;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  transition: border-color .1s ease;
}
select:read-only, input:read-only, textarea:read-only {
  color: #7B919D;
}
textarea {  
  font-family: 'Roboto', sans-serif;
  height: auto;
  min-height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: vertical;
}
input:hover, textarea:hover {
    border-color: #b3b3b3;
}
select {
  background-color: #fff;
  width: auto;
}
input[type="checkbox"]{
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
  border: none;
}
input[type="range"]{
    padding: 0;
    height: 36px;
    border: none;
}

.rangeInput {
    display: flex;
    gap: 10px;
    border: 1px solid #f4f7f9;
    border-radius: 5px;
}
.rangeInput input[type="range"]{
    margin-right: 10px;
}
.rangeInput input[type="number"]{
    width: 60px;
}


.checkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkContainer span {
  margin-left: 6px;
  font-size: 14px;
}

.message {
  color: #fff;
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  border-radius: 2px;
}
.message.error {
  background-color: #da4141;
}
.message.success {
  background-color: #2daf70;
}


.blueText {  
  margin: 16px;
  color: #008cdb;
  font-weight: 500;
  font-size: 14px;
}


#sidebar {
  background-color: #161a1d;
  width: 270px;
  height: 100vh;
  flex-shrink: 0;
  transition: width .2s ease-out;
}

@media (max-width:1500px){

  #sidebar {
    width: 80px;
  }
  #sidebar nav a {
    margin: 0 5px !important;
    justify-content: center;
  }
  #sidebar nav a svg {
    margin: 0 !important;
  }
  #sidebar .logoContainer {
    padding: 12px 20px !important;
  }

}

#content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 0 30px;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: #2a333a;
}
#pagetitle {
  font-size: 18px;
  color: #A1B2BC;
  font-weight: 500;
  text-transform: uppercase;
}
#pageSubtitle {
  font-size: 16px;
  color: #A1B2BC;
  font-weight: 300;
  margin-left: 25px;
}

#profileMenu {
  margin-left: auto;
  min-width: initial;
}
#profileMenu .myself {
  display: flex;
  color: #A1B2BC;
  font-size: 14px;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border-radius: 20px;
  padding-left: 20px;
  border: 1px solid #435461;
}
#profileMenu .myself:hover {
  background-color: #353f46;
}
#profileMenu .myself:active {
  background-color: #1C282F;
}
#profileImage {
  display: flex;
  background-color: #008cdb;
  background-size: cover;
  background-position: center;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
#profileMenu .account-status-options {
    border-radius: 20px;
    padding: 5px 5px;
}
#profileMenu .account-status-option {
    display: flex;
    gap: 8px;
    border-radius: 20px;
    padding: 3px 10px;
}
#profileMenu .account-status-option svg {
    width: 24px;
}

.page {
  height: 100vh !important;
}
.page.offset {
  height: calc(100vh - 125px) !important;
}
.page .page-content {  
  padding: 30px 30px 40px 30px;
  box-sizing: border-box;
}
.sidebarScrollFix {
  height: calc(100% - 62px) !important;
}
/* Scrollbar styles */
.page > div:last-child, .chatScroll > div:last-child {
  top: 4px !important;
  bottom: 4px !important;
}
.page > div:last-child > div, .chatScroll > div:last-child > div {
  background-color: #dce2e6 !important;
  width: 4px !important;
}
.page.dark-scrollbar > div:last-child > div {
  background-color: #637380  !important;
}


#sidebar .logoContainer {
    border-bottom: 1px solid #2a333a;
    padding: 15px 25px 14px;
    transition: padding .2s ease-out;
}
#sidebar .logo {
  width: 160px;
  box-sizing: border-box;
}
#sidebar .logoIcon {
  display: none;
  width: 40px;
  box-sizing: border-box;
}

@media (max-width:1500px){

  #sidebar .logo { display: none; }
  #sidebar .logoIcon { display: block; }

}

#sidebar nav ul {
  list-style-type: none;
  padding: 25px 0;
  margin: 0;
}
#sidebar nav ul li {
  position: relative;
  margin-bottom: 1px;
}
#sidebar nav ul a {
  display: flex;
  align-items: center;
  color: #A1B2BC;
  text-decoration: none;
  padding: 0 10px;
  min-height: 47px;
  font-size: 14px;
  font-weight: 400;
  margin: 0 15px;
  border-radius: 4px;
}
#sidebar nav ul a:hover, #sidebar nav ul li.active a {
  color: #fff;
  background-color: #222a31;
}
#sidebar nav ul a:active {
    background-color: #0c0f10;
}
#sidebar nav ul svg {
  font-size: 17px;
  margin-right: 12px;
  transition: margin-left .1s ease-out;
}
#sidebar nav ul a:hover svg {
    margin-left: 6px;
}
.menuTooltip {
  display: none;
  position: absolute;
  z-index: 99;
  left: 100%;
  top: 0;
  height: 47px;
  white-space: nowrap;
  background-color: #161a1d;
  align-items: center;
  padding: 0 20px;
  color: #A1B2BC;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media (max-width:1500px){

  #sidebar nav a span { display: none; }
  #sidebar nav ul a:hover .menuTooltip { display: flex; }

}


.count {  
  /* display: inline-block;
  background-color: #ff5757;
  min-width: 18px;
  padding: 0 4px;
  height: 22px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  margin-left: 12px; */
  
  background-color: #ff5757;
  min-width: 28px;
  padding: 0 7px;
  height: 28px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  box-sizing: border-box;
  margin-left: auto;
}
.tab.active > .count {
  background-color: #fff;
  color: #161a1d;
}


.window {
  background-color: #fff;
  box-shadow: 0 3px 11px 0 rgba(68, 107, 139, .1);
  border-radius: 3px;
  border: 1px solid #fff;
  margin-bottom: 30px;
}
.window-head {
  display: flex;
  height: 49px;
  padding-left:20px;
  align-items: center;
  background-color: #ebf0f4;
  border-radius: 3px;
  border-bottom: 1px solid #d4e1eb;
  user-select: none;
}
.window-title {
  color: #29363E;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}
.window-content {
    overflow: hidden;
}

.window-head button {
  margin-left: 1px;
  margin-top: -1px;
  margin-right: 3px;
}
.window-head button.active {
  background-color: #008cdb;
  color: #fff;
}
.window-head .collapse {
    padding: 8px 10px;
    margin-left: -15px;
    margin-right: 3px;
    cursor: pointer;
}
.window-head .collapse:hover {
    opacity: .6;
}
.window-head .count {    
    margin-left: 15px !important;
}


.container {
  position: relative;
  padding: 25px 30px;
}
.containerSmall {
  position: relative;
  padding: 15px 20px;
}
.subtitle {
  font-size: 13px;
  font-weight: 700;
  color: #008cdb;
  margin-bottom: 15px;
  padding-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid #d6e1e9;
} 


.modal-wrapper {
  display: flex;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(40 48 56 / 90%);
  overflow-x: auto;
  padding: 80px 0;
  box-sizing: border-box;
}
.modal {
  margin: auto;
  width: 100%;
  max-width: 920px;
  z-index: 99;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #fff;
  white-space: initial;
}
.modal.small {
  max-width: 500px;
}
.modal-head {
  display: flex;
  height: 49px;
  padding-left:20px;
  align-items: center;
  background-color: #ebf0f4;
  border-radius: 3px;
  user-select: none;
}
.modal-title {
    color: #29363E;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
}
button.modal-close {
    height: 38px;
    margin-left: auto;
    margin-right: 5px;
    font-size: 16px;
    background-color: transparent;
}
button.modal-close svg {
    margin-right: 0;
}
.modal-content {
  padding: 20px;
}
.modal-actions {
  padding: 20px;
  border-top: 1px solid #E0E8EF;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modal-actions button {
    height: 52px;
    padding: 0px 24px;
    margin-left: 5px;
}


.notice {
  padding: 20px;
  border-bottom: 1px solid #D6E1E9;
}
.notice:last-child {
  border: none;
}
.notice_comment {  
  font-size: 15px;
  color: #3A4D58;
  line-height: 24px;
  white-space: break-spaces;
}
.notice_meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: #7B919D;
}
.notice.pinned {
    background-color: #fffde7;
    border-color: #e3d6af;
}
.notice.pinned .notice_comment {
    color: #7d6212;
}
.notice.pinned .notice_meta {
    color: #b9ac7a;
}
.notice.pinned .outlineButton {
    border-color: #d7c598;
    color: #7d6212;
}
.notice.pinned .pinButton {
    background-color: #fdea92;
}


.table-head {
  display: flex;
  gap: 10px;
  color: #29363E;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 20px;
  padding: 20px 20px 5px 20px;
  border-bottom: 1px solid #E0E8EF;
}
.table-head .table-col.pseudo {
  min-width: 104px;
}
.table-head .table-col:not(.pseudo) {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.table-head .table-col:hover {
  color: #008cdb;
}
.table-col.xxs {  
  flex-basis: 40px;
  flex-grow: 0;
}
.table-col.xs {  
  flex-basis: 60px;
  flex-grow: 0;
}
.table-col.s {  
  flex-basis: 120px;
  flex-grow: 0;
}
.table-col.m {  
  flex-basis: 140px;
  flex-grow: 0;
}
.table-col.l {  
  flex-basis: 200px;
  flex-grow: 0;
}
.table-col.xl {  
  flex-basis: 350px;
  flex-grow: 0;
}
.table-col.xxl {
  flex-basis: 800px;
  flex-grow: 0;
  word-break: break-all;
}

.sortIcon {
  color: #D6E1E9;
  margin-left: 5px;
}
.sortIcon.active {
  color: #1C282F;
}
.table-body {
  padding: 10px 0;
}
.table-row {
  display: flex;
  gap: 10px;
  padding: 4px 20px;
  font-size: 14px;
  line-height: 190%;
  align-items: center;
}
.table-row:hover {
  background-color: #F6F8FA;
}
.table-row.highlight {
  color: #0378bb;
}

.table-row.priority1 {
    background-color: #ffe3e3;
}
.table-row.priority1:hover {
    background-color: #ffdfdf;
}
.table-row.priority1 .table-col.xl {    
    color: #f74b4b;
    font-weight: bold;
}
.table-row.priority1 .table-actions button {   
    border-color: #9da8b1;
}

.table-row.priority2 {
  background-color: #e2f7e8;
}
.table-row.priority2:hover {
    background-color: #dbf2e2;
}
.table-row.priority2 .table-col.xl {    
    color: #f74b4b;
    font-weight: bold;
}
.table-row.priority2 .table-actions button {   
    border-color: #9da8b1;
}

.table-col {
  flex: 1;
}
.table-actions {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}
.table-actions button {
    height: 30px;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    background-color: transparent;
    border: 1px solid #d6e1e9;
    text-transform: initial;
    color: #333;
}
.table-actions button:hover {
    border-color: #008cdb;
    color: #008cdb;
}
.table-actions button:active {
    border-color: #008cdb;
    color: #008cdb;
}
.table-actions button:active {
    background-color: #ccd7df;
}

.table-footer {
  display: flex;
  height: 70px;
  padding: 0 20px;
  border-top: 1px solid #E0E8EF;
  align-items: center;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 20px;
}
.pagination li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #3A4D58;
  font-size: 14px;
  width: 30px;
  height: 30px;
  border: 1px solid #D6E1E9;
  border-right: none;
  cursor: pointer;
}
.pagination li:hover {
  background-color: #F6F8FA;
}
.pagination li.active {
  background-color: #008cdb;
  border-color: #008cdb;
  color: #fff;
  cursor: default;
}
.pagination li.disabled {
  cursor: default;
  color: #A1B2BC;
}
.pagination li.disabled:hover {
  cursor: default;
  background-color: transparent;
}
.pagination li:first-child { 
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 10px;
}
.pagination li:last-child {  
  border-right: 1px solid #D6E1E9;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 10px;
}

.ppp { 
  font-weight: 500;
  color: #3A4D58;
  font-size: 14px;
  height: 32px;
  padding: 0 10px;
  min-width: 160px;
  border: 1px solid #D6E1E9;
  cursor: pointer;
  border-radius: 3px;
  margin-left: auto;
}


.filters {
  position: relative;
  background-color: #2a333a;
  padding: 10px 20px;
}
.filters > .row {
  flex-wrap: wrap;
}
.filters label, .filters span {
  color: #fff;
}
.search-input {
    height: 43px;
    width: 300px;
    background-color: #ffffff;
    border: 1px solid #cbd7e1;
    vertical-align: middle;
    margin-right: 3px;
}
.search-input:focus {
    border-color: #008cdb;
}


.blueInfo {  
  display: flex;
  border: 1px solid #008cdb;
  background-color: #caecff;
  border-radius: 3px;
  align-items: center;
}
.blueInfo.autobreak {
  background: repeating-linear-gradient(-45deg, #b1e1ff, #b1e1ff 5px, #c4eaff 5px, #c4eaff 10px);
}

.infoBox {
  padding: 6px 10px;
  line-height: 14px;
  font-size: 14px;
}
.infoBox.yellow { background-color: #fff4c5; }


.groupCount {
  display: inline-block;
  background-color: #86a1b6;
  color: #fff;
  margin-left: 8px;
  padding: 4px 6px 3px;
  border-radius: 3px;
  line-height: 18px;
  font-size: 12px;
  font-weight: bold;
}


.account-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.user-status {
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  padding: 3px;
  font-weight: 500;
  font-size: 13px;
  line-height: initial;
  width: calc(100% - 10px);
  max-width: 80px;
  border: 1px solid #506978;
}
.user-status.new {
  border-color: #008cdb;
  color: #008cdb;
}
.user-status.inactive {
  background-color: #D6E1E9;
  border-color: #D6E1E9;
  color: #506978;
}
.user-status.active {
  background-color: #008cdb;
  border-color: #008cdb;
  color: #fff;
}
.user-status.pausing {
  background-color: #3A4D58;
  border-color: #3A4D58;
  color: #E2EBF0;
}
.user-status.locked {
  background-color: #E5614F;
  border-color: #E5614F;
  color: #fff;  
}
.user-status.prepared {
  background-color: #fff;
  border-color: #7b919d;
  border-style: dashed;
  color: #506978;
}


.confirm-status {
    display: inline-block;
    text-align: center;
    border-radius: 20px;
    padding: 3px;
    font-weight: 500;
    font-size: 13px;
    line-height: initial;
    width: calc(100% - 10px);
    max-width: 90px;
    border: 1px solid #506978;
    cursor: pointer;
  }
  .confirm-status:hover {
    opacity: .8;
  }
  .confirm-status.open {
    border-color: #008cdb;
    color: #008cdb;
  }
  .confirm-status.confirmed {
    background-color: #2FC08C;
    border-color: #2FC08C;
    color: #fff;
  }
  .confirm-status.denied {
    background-color: #E5614F;
    border-color: #E5614F;
    color: #fff;  
  }
  


.task-status {
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  padding: 3px;
  font-weight: 500;
  font-size: 13px;
  line-height: initial;
  width: calc(100% - 10px);
  max-width: 90px;
  border: 1px solid #506978;
}
.task-status.new {
  border-color: #008cdb;
  color: #008cdb;
}
.task-status.finished {
  background-color: #2FC08C;
  border-color: #2FC08C;
  color: #fff;
}
.task-status.active {
  background-color: #008cdb;
  border-color: #008cdb;
  color: #fff;
}

.task-activity {
  border-radius: 3px;
  border: 1px solid #d6e1e9;
  padding: 5px 12px;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 5px;
}
.task-activity-head {
  font-weight: 500;
}
.task-activity-head > .col:last-child {
  text-align: right;
}
.task-activity .row {  
  margin: 5px 0;
}
.task-activity .taskstate0 {
  font-weight: 500;
  color: #506978;
}
.task-activity .taskstate1 {
  font-weight: 500;
  color: #2FC08C;
}
.task-activity .taskstate2 {
  font-weight: 500;
  color: #008cdb;
}


.request-status {
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  padding: 3px;
  font-weight: 500;
  font-size: 13px;
  line-height: initial;
  width: 90px;
  border: 1px solid #506978;
}
.request-status.open {
  border-color: #008cdb;
  color: #008cdb;
}
.request-status.declined {
  background-color: #E15046;
  border-color: #E15046;
  color: #fff;
}
.request-status.accepted {
  background-color: #2FC08C;
  border-color: #2FC08C;
  color: #fff;
}
.request-status.timedout {
  background-color: #506978;
  color: #fff;
}


.care-level {  
  display: inline-block;
  border: 1px solid;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: initial;
  padding-top: 1px;
  box-sizing: border-box;
}

.rating {
  font-size: 15px;
}

.ratingSelect {
  display: flex;
}
.ratingSelect .star {
  cursor: pointer;
  font-size: 24px;
}




#profile {
  display: flex;
}
.profile-sidebar {
  background-color: #fff;
  width: 320px;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid #D6E1E9;
}
.profile-sidebar-head {
  display: flex;
  border-bottom: 1px solid #D6E1E9;
  padding: 0 30px;
  height: 60px;
  align-items: center;
}
.profile-content {
  width: 100%;
}
.profile-tabs {
  display: flex;
  background-color: #fff;
  height: 60px;
  border-bottom: 1px solid #D6E1E9;
}
.profile-tabs .tab {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #2a333a;
  font-weight: 500;
  font-size: 14px;
  padding: 0 20px;
  text-decoration: none;
  border-right: 1px solid #d6e1e9;
}
.profile-tabs .tab span {
    font-size: 13px;
}
.profile-tabs .tab:hover {
    background-color: #f5f7f9;
    color: #008cdb;
}
.profile-tabs .tab:active {
    background-color: #d1dde5;
}
.profile-tabs .tab.active {
  background-color: #008cdb;
  color: #fff;
}

@media (max-width:1400px){

  .profile-tabs .tab {
    font-size: 12px;
    padding: 0 12px;
  }

}


.profileField {
  display: inline-block;
  background: #f4f7f9;
  border-radius: 2px;
  padding: 2px 6px;
  margin: 0 3px 6px;
  outline: 1px solid #D6E1E9;
}

.profileDesc {
  font-size: 13px;
  font-weight: 500;
  color: #A1B2BC;
  margin-bottom: 2px;
}
.profileDesc svg {
  margin-right: 5px;
}
.profileName {
  font-size: 18px;
  margin-bottom: 10px;
}
.profile-infotable {
  font-size: 14px;
  color: #3A4D58;
  line-height: 150%;
}
.profile-infotable .row {
  margin-top: 5px;
  margin-bottom: 5px;
}
.profile-infotable .col:last-child {
  text-align: right;
  font-weight: 500;
  white-space: nowrap;
}

.profile-table {
  font-size: 14px;
  color: #3A4D58;
  line-height: 150%;
}
.profile-table .row {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid #ebf0f4;
  padding-top: 6px;
}
.profile-table .row:first-child {
  border-top: none;
}
.profile-table .col:last-child:not(:first-child) {
  font-weight: 500;
}


.languageBar {
    border: 1px solid #d6e1e9;
    width: 100%;
    height: 10px;
}
.languageBar > div {
    position: relative;
    height: 100%;
    max-width: 100%;
}
.languageBar > div span {
    position: absolute;
    display: block;
    font-weight: bold;
    font-size: 15px;
    right: 0;
    line-height: 10px;
    transform: translateX(50%);
    text-shadow: rgb(255, 255, 255) 1px 0px 0px, rgb(255, 255, 255) 0.540302px 0.841471px 0px, rgb(255, 255, 255) -0.416147px 0.909297px 0px, rgb(255, 255, 255) -0.989992px 0.14112px 0px, rgb(255, 255, 255) -0.653644px -0.756802px 0px, rgb(255, 255, 255) 0.283662px -0.958924px 0px, rgb(255, 255, 255) 0.96017px -0.279415px 0px;
}


.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.tabs:before {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  transform: translateY(18px);
  background-color: #D6E1E9;
}
.tabs .tab {
  position: relative;
  background-color: #fff;
  border: 1px solid #D6E1E9;
  text-transform: uppercase;
  color: #506978;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 20px;
  border-right: none;
  cursor: pointer;
}
.tabs .tab:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.tabs .tab:last-child {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-right: 1px solid #D6E1E9;
}
.tabs .tab:hover {
  background-color: #D6E1E9;
}
.tabs .tab.active {
  border-color: #008cdb;
  background-color: #008cdb;
  color: #fff;
}


.assignedUser {  
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 1px);
  border: 1px dashed #d6e1e9;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #38505e;
  font-size: 14px;
  cursor: pointer;
  padding: 0 5px;
}
.assignedUser:hover {
  color: #008cdb;
  border-color: #7b919d;
}
.assignedUser span {
  font-size: 12px;
}
.assignedUserName {
    color: #af1a85;
    margin: 3px 0;
    font-weight: bold;
    font-size: 14px;
}


.media-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}
.media {
  cursor: pointer !important;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.media:after {
  display: block;
  content: '';
  padding-bottom: 100%;
}
.media.video:before {
  display: block;
  content: '';
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 14px);
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
  border-width: 16px 0 16px 28px;
}
.media-full {
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.media .deleteMedia {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 107, 139, .4);
  color: #da4141;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
.media:hover .deleteMedia {
  display: flex;
}
.download-media-link {  
  position: absolute;
  width: 100%;
  top: 102%;
  text-align: center; 
  color: #fff;
  font-size: 14px;
}
.download-media-link:hover {
  color: #008cdb;
}


.account-status-selector {
  position: relative;
  font-size: 14px;
  color: #7B919D;
  min-width: 160px;
}
.account-status-selection {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #D6E1E9;
  height: 30px;
  border-radius: 15px;
  padding-right: 40px;
  cursor: pointer;
}
.account-status-selection:after {
  position: absolute;
  right: 10px;
  margin-top: 1px;
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 3px 0 3px;
  border-color: #7b919d transparent transparent transparent;
}
.account-status-selection:hover {
  background-color: #F6F8FA;
  border-color: #bacedd;
}
.account-status-selector .account-status, .account-status-option .account-status {
  margin: 0 8px 0 5px;
}
.account-status-options {
  position: absolute;
  z-index: 9;
  top: 0;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(68, 107, 139, .4);
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #D6E1E9;
}
.account-status-option {
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;
  padding: 1px 0;  
  color: #293238;
}
.account-status-option:hover {
  background-color: #F6F8FA;
}
.account-status-selector.noarrow .account-status-selection {
  cursor: default;
}
.account-status-selector.noarrow .account-status-selection::after {
  display: none;
}
.account-status-selector.noarrow .account-status-selection:hover {
  background-color: initial;
}


.settingsButton {
  margin-left: auto;
  font-size: 20px;
  color: #7b919d;
  cursor: pointer;
}
.settingsButton:hover {
  color: #1C282F;
}




.uploader section {
  color: #7B919D;
  margin-bottom: 20px;
}
.uploader section > div {
  border: 2px dashed #7B919D;
  padding: 5px 20px;
  outline-color: #008cdb;
  border-radius: 3px;
  cursor: pointer;
}




.doc {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.docImage {
  width: 34px;
  margin-right: 10px;
}
.docTitle {
  font-size: 14px;
  font-weight: 500;
}
.docMeta {
  display: flex;
  font-size: 14px;
  color: #7B919D;
  line-height: 24px;
}
.docMeta span {
  margin-right: 10px;
}
.docMeta span:after {
  display: inline;
  content: '•';
  margin-left: 10px;
}
.docMeta span:last-child {
  margin-right: 0;
}
.docMeta span:last-child:after {
  display: none;
}


.doc .downloadButton, .doc .viewButton, .doc .deleteButton, .break .deleteButton {
  background-color: #D6E1E9;
  color: #506978;
  height: 40px;
  width: 40px;
  border-radius: 3px;
  padding: 0;
  margin-left: 10px;
}
.doc .downloadButton svg, .doc .viewButton svg, .doc .deleteButton svg, .break .deleteButton svg {
  font-size: 16px;
  margin: 0;
}
.doc .downloadButton:hover, .doc .viewButton:hover {
  background-color: #008cdb;
  color: #fff;
}
.doc .deleteButton:hover, .break .deleteButton:hover {
  background-color: #E15046;
  color: #fff;
}


.break .deleteButton {
  margin-left: 0;
}
.break label {
  line-height: 40px;
}
.breakIndex {
  background-color: #D6E1E9;
  color: #506978;
  height: 40px;
  width: 40px;
  border-radius: 3px;
  padding: 0;
  flex-shrink: 0;
  text-align: center;
}
.breakIndex label {
  font-size: 18px;
  color: #7B919D;
}
.breakIndex span {
  font-size: 14px;
}



.lightbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(40 48 56 / 90%);
  padding: 80px 120px;
  box-sizing: border-box;
}
.lightbox-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.lightbox-close {  
  position: absolute;
  z-index: 2;
  padding: 36px 44px;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 36px;
  cursor: pointer;
  opacity: 1;
}
.lightbox-prev, .lightbox-next {  
  position: absolute;
  z-index: 2;
  padding: 36px 44px;
  top: calc(50% - 60px);
  color: #fff;
  font-size: 36px;
  cursor: pointer;
  opacity: 1;
}
.lightbox-prev { left: 0; }
.lightbox-next { right: 0; }
.lightbox-prev:hover, .lightbox-next:hover, .lightbox-close:hover { opacity: .8; }
.lightbox img {
  position: absolute;
  z-index: 1;
  max-width: calc(100% - 240px);
  max-height: calc(100% - 240px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lightbox video {
  position: absolute;
  z-index: 1;
  max-width: calc(100% - 240px);
  max-height: calc(100% - 240px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}
.lightbox iframe {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1000px;
  height: 100%;
  border: 0;
}

.loading-video {
  color: #fff;
}



.profileSelect input {
  height: 25px;
}










.chatDate {
  font-weight: 500;
  color: #3A4D58;
  font-size: 14px;
  margin: 40px 0 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d6e1e9;
}
.chatScroll {
  min-height: 500px;
  max-height: calc(100vh - 300px);
}
.chatMessages {
  padding: 0 20px 15px;
  box-sizing: border-box;
}
.chatMessage {
  position: relative;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 400;
  background-color: #D6E1E9;
  color: #161a1d;
  max-width: 70%;
  border-radius: 3px;
  margin-bottom: 10px;
  box-sizing: border-box;
  white-space: pre-wrap;
  margin-left: auto;
}
.chatMessage.user {
  background-color: #008cdb;
  color: #fff;
  margin-left: 0;
}
.chatMessage.datei {
  padding: 0;
  overflow: hidden;
}
.chatAuthor {  
  color: #3A4D58;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}
.chatTime {  
  position: absolute;
  color: #a1b2bc;
  left: 0;
  top: 50%;
  font-size: 12px;
  margin-top: -6px;
  margin-left: -45px;
}
.chatMessage.user .chatTime {  
  right: 0;
  left: auto;
  margin-right: -45px;
}
.chatControls {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #D6E1E9;
  padding: 20px;
}
.chatControls textarea {
  resize: none;
  min-height: 50px;
  max-height: 200px;
  height: 50px;
  padding: 16px 14px;
  box-sizing: border-box;
  border-top-right-radius: 0;
}
.chatControls button {
    height: 50px;
}
.chatControls button:hover {
  background-color: #008cdb;
}


.newChat {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px;
  border-bottom: 1px solid #E0E8EF;
}
.chatCount {
  font-weight: bold;
}
.chatBy {
  font-size: 14px;
}


.translation-text {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #29363E;
  line-height: 22px;
  font-size: 15px;
}



.ck-content {
  width: 100%;
  box-sizing: border-box;
  background-color: #f4f7f9;
  border: 1px solid #d6dfe5 !important;
  transition: border-color .1s ease;
}
.ck-content:hover {
    border-color: #b3b3b3 !important;
}




.timeline {
  margin: 20px;
}
.timeline-months {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.timeline-months > div {
  font-size: 12px;
  flex-basis: 60px;
  text-align: center;
}
.timeline-line {
  position: relative;
  display: flex;
  background-color: #EBF0F4;
  height: 15px;
  margin: 10px 0;
  border-radius: 15px;
  overflow: hidden;
}
.timeline-today {
  position: absolute;
  margin-top: -32px;
  font-size: 20px;
}
.timeline-care {
  position: absolute;
  height: 15px;
  width: 0;
  background-color: #38505E;
}
.timeline-care.previous { background-color: #D6E1E9; }
.timeline-care.current { background-color: #008cdb; }
.timeline-care.planned {  
  background-color: #fff;
  box-shadow: 0 0 0 1px rgb(21 152 226) inset;
}
.timeline-pause {
  position: absolute;
  height: 15px;
  width: 0;
  background: repeating-linear-gradient(
    -45deg,
    #5e7c93,
    #5e7c93 5px,
    #9ea9b1 5px,
    #9ea9b1 10px
  );
}
.timeline-auto-pause {
  position: absolute;
  height: 15px;
  width: 0;
  background: repeating-linear-gradient(
    -45deg,
    #5e7c93,
    #5e7c93 5px,
    #9ea9b1 5px,
    #9ea9b1 10px
  );
}
.timeline-care.previous .timeline-auto-pause, .timeline-pause.previous {
  background: repeating-linear-gradient(
    -45deg,
    #D6E1E9,
    #D6E1E9 5px,
    #bacedd 5px,
    #bacedd 10px
  );
}
.timeline-care.current .timeline-auto-pause {
  background: repeating-linear-gradient(
    -45deg,
    #b3d8ed,
    #b3d8ed 5px,
    #5ab4e7 5px,
    #5ab4e7 10px
  );
}
.timeline-care.planned .timeline-auto-pause {
  background: repeating-linear-gradient(
    -45deg,
    #98c5df,
    #98c5df 5px,
    #fff 5px,
    #fff 10px
  );
  box-shadow: 0 0 0 1px rgb(21 152 226) inset;
  margin-left: -1px;
}
.timeline-meta {
  position: relative;
  display: flex;
  height: 32px;
  margin: 10px 0;
}
.timeline-care-meta {
  position: absolute;
  color: #38505E;
}
.timeline-care-meta.previous { color: #D6E1E9; }
.timeline-care-meta.current { color: #008cdb; }
.timeline-meta-date {
  font-weight: bold;
  font-size: 14px;
}
.timeline-pause {
  color: #5e7c93;
}
.timeline-pause.previous { color: #D6E1E9; }
.timeline-pause.current { color: #008cdb; }
.timeline-pause .timeline-meta-date {
  margin-top: 25px;
}
.timeline-meta-name {
  font-size: 14px;
}


.score {
  font-size: 16px;
  font-weight: bold;
}

.maxInputChars {
  font-size: 12px;
  font-weight: 500;
  color: #008cdb;
}


/* Contract Tooltip */

.tooltip {
  display: inline-block;
  cursor: default;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #def1ff;
  color: #2e3235;
  font-size: 14px;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #65c4ff;
  padding: 10px 15px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  left: 20px;
  right: 20px;
  margin-top: 30px;
  opacity: 0;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  transition: opacity 0.3s;
}
.tooltip .tooltiptext::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 40%;
  width: 12px;
  height: 12px;
  transform: rotate(45deg) translateY(-10px);
  background-color: #def1ff;
  border: 1px solid #65c4ff;
  border-right: none;
  border-bottom: none;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


/* Alert */

.alert-message {
  z-index: 99999;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
}
.pop {
  animation-name: pop;
  animation: pop 5s ease;
  animation-fill-mode: forwards;
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(.6);
  }
  6% {
    transform: translateX(-50%) scale(1.05);
  }
  10% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

/* Loader */

#loader {  
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgb(40 48 56 / 90%);
  position: absolute;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}  
.spinner.small {
    position: static;
    margin: 0 0 0 10px;
    width: 20px;
    height: 20px;
    vertical-align: sub;
}
.spinner .path {
  stroke: #fff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
.spinner.dark .path {
  stroke: #7B919D;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


.csv-label {  
  display: block;
  margin-bottom: 5px;
}
.csv-input {  
  padding: 8px;
}



/* Disable Text Selection */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}





/* DARK MODE */
/* 
#app {
  color: #b7c2cb;
  background-color: #090b0c;
}
#sidebar {
  background-color: #090b0c;
}
hr {
    background-color: #2a333a;
}
label {
  color: #b7c2cb;
}
button {
  background-color: #506978;
  color: #fff;
}
.cancelButton {
  background-color: #2a333a;
  color: #b7c2cb;
}
.cancelButton:hover {
  background-color: #506978;
  color: #ffffff;
}
a:hover, .link:hover {
  color: #fff;
}
.modal {
  background-color: #161a1d;
  border: 1px solid #2a333a;
}
.modal-head {
  background-color: #090b0c;
}
.modal-title {
  color: #fff;
}
.modal-actions {
  border-top: 1px solid #2a333a;
}
.window {
  background-color: #161a1d;
  border: 1px solid #2a333a;
}
.window-head {
  background-color: #2a333a;
}
.window-title {
  color: #fff;
}
.table-head {
  color: #fff;
  border-bottom: 1px solid #2a333a;
}
.table-row:hover {
  background-color: #2a333a;
}
.table-footer {
  border-top: 1px solid #2a333a;
}
.profile-tabs {
  background-color: #161a1d;
  border-bottom: 1px solid #2a333a;
}
.profile-tabs .tab {
  color: #fff;
}
.profile-tabs .tab:hover {
  background-color: #506978;
}
.table-actions button {
  border: 1px solid #506978;
}
select {
  background-color: #2a333a;
  color: #b6c4cd;
}
input, textarea {
  background-color: #090b0c;
  color: #b6c4cd;
}
.ppp {
  color: #b7c2cb;
  border: 1px solid #506978;
}
.pagination li {
  color: #b7c2cb;
  border: 1px solid #506978;
  border-right: none;
}
.pagination li:last-child {
  border-right: 1px solid #506978;
}
.pagination li.disabled {
  color: #2a333a;
}
.pagination li:hover {
  background-color: #2a333a;
}
.profile-sidebar {
  background-color: #161a1d;
  border-right: 1px solid #2a333a;
}
.profile-sidebar-head {
  border-bottom: 1px solid #2a333a;
}
.profile-infotable {
  color: #b7c2cb;
}
.profile-table {
  color: #b7c2cb;
}
.profile-table .row {
  border-top: 1px solid #2a333a;
}
.subtitle {
  border-bottom: 1px solid #506978;
}
.profileField {
  background: #506978;
  color: #000;
}
.tabs .tab {
  background-color: #2a333a;
  border: 1px solid #506978;
  color: #b7c2cb;
}
.tabs .tab:hover {
  background-color: #506978;
  color: #fff;
}
.tabs .tab:last-child {
  border-right: 1px solid #506978;
}
.tabs:before {
    background-color: #2a333a;
}
.tabs .tab.active {
  border-color: #008cdb;
  background-color: #008cdb;
  color: #fff;
}
.chatControls {
  border-top: 1px solid #2a333a;  
}

.selectbox [class*="control"] {
  background-color: #090b0c;
}
.selectbox [class*="singleValue"] {
  color: #b7c2cb;
}
.selectbox [class*="multiValue"] {
  background-color: #2a333a;
}
.selectbox [class*="multiValue"] > div {
  color: #b7c2cb;
}
.selectbox [class*="indicatorSeperator"] {
  background-color: #161a1d;
}
.selectbox [class*="indicatorContainer"] {
  color: #506978;
}
.selectbox [class*="menu"] {
  background-color: #2a333a;
}
.selectbox [class*="option"]:hover {
  background-color: #506978;
}
*/